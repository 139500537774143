import styled, { css } from "styled-components"
import { space, height } from "styled-system"
import { outerContainer } from "../../../config/mixins"
import theme from "../../../config/theme"
import { mq } from "../../../config/mq"

///

const fullStyle = css`
  margin-left: -16px;
  margin-right: -16px;
`
const containerStyle = css`
  ${outerContainer()}

  ${mq.xsOnly(fullStyle)}
`
export const BannerWrapper = styled.div`
  ${height}
  ${space}

  background: ${theme.colors.fg};
  color: ${theme.colors.bg};
  text-align: center;
  max-height: 25rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${props => props.width === "full" && fullStyle}
  ${props => props.width === "container" && containerStyle}

  >img {
    width: 100%;
    object-fit: cover;
    max-width: 120rem;

    @supports (object-fit: cover) {
      height: 100%;
    }
  }
`
BannerWrapper.defaultProps = {
  mb: 4,
  height: ["12rem", "25vw"],
}
