import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { ComicList } from "../components/ComicList"
import Banner from "../components/Banner"
import { PageTitle, BodyText } from "../components/Typography"

///

class ComicIndexTemplate extends React.Component {
  render() {
    const post = this.props.data.post
    let childPosts = this.props.data.childPosts.edges
    const baseUrl = this.props.data.site.siteMetadata.siteUrl

    if (!post.frontmatter.layout) {
      post.frontmatter.layout = "card"
    }

    // =================
    // get children of this post (folders inside this post)
    // =================

    const newChildPosts = childPosts.filter(childPost => {
      let postSegment = post.fields.slug.split("/")
      let childPostSegment = childPost.node.fields.slug.split("/")
      let levelDiff = childPostSegment.length - postSegment.length

      if (childPost.node.id === post.id) {
        return false
      }
      if (levelDiff > 1) {
        return false
      }
      return true
    })
    childPosts = newChildPosts

    // =================
    // breadcrumb data (not used)
    // =================

    // const breadcrumb = []
    // if (parentPost) {
    //   breadcrumb[0] = {
    //     label: parentPost.frontmatter.title,
    //     slug: parentPost.fields.slug,
    //   }
    //   const currPage = {
    //     label: post.frontmatter.title,
    //     isCurrent: true,
    //   }
    //   breadcrumb.push(currPage)
    // }
    // const breadcrumbNode = <Breadcrumb data={breadcrumb} />

    // =================
    // get language & image for SEO
    // =================

    let seoLang = "en"
    if (post.frontmatter.lang) {
      if (post.frontmatter.lang.length === 1 && post.frontmatter.lang[0] === "id") {
        seoLang = "id"
      }
    }

    let seoImg
    if (typeof post.frontmatter.thumbnail.childImageSharp !== "undefined") {
      seoImg = baseUrl + post.frontmatter.thumbnail.childImageSharp.resize.src
    }

    const seoUrl = baseUrl + post.fields.slug.replace("/comics/", "/")

    // =================
    // get banner, if any
    // =================

    let comicBanner
    if (post.frontmatter.banner) {
      if (post.frontmatter.banner.childImageSharp != null) {
        comicBanner = (
          <Banner
            width="full"
            as={Img}
            fluid={post.frontmatter.banner.childImageSharp.fluid}
            alt=""
            role="presentation"
            aria-hidden
          />
        )
      }
    }

    // =================

    return (
      <Layout>
        <SEO
          isArticle
          comicType="index"
          title={post.frontmatter.title}
          pubDateTime={post.frontmatter.date}
          description={
            post.frontmatter.description
              ? `${post.frontmatter.description.substring(0, 280)}…`
              : post.excerpt
          }
          url={seoUrl}
          image={seoImg}
          lang={seoLang}
        />

        {comicBanner}

        <PageTitle>{post.frontmatter.title}</PageTitle>
        <BodyText
          className="AcLayout__outer-container"
          mt={[3, 4]}
          mb={[4, 5]}
          px={[0, 4, 5]}
          style={{ textAlign: "center" }}
        >
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </BodyText>

        {childPosts.length ? (
          <ComicList data={childPosts} variant={post.frontmatter.layout} />
        ) : (
          false
        )}
      </Layout>
    )
  }
}

export default ComicIndexTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String! # $parentSlug: String
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD") # for SEO published date
        description
        layout
        lang
        banner {
          childImageSharp {
            fluid(
              maxWidth: 1920
              maxHeight: 533
              quality: 50
              traceSVG: { color: "#ddd", background: "#f2f2f2" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            resize(width: 1440, height: 400, quality: 50) {
              src
            }
          }
        }
        thumbnail {
          # for SEO image
          childImageSharp {
            resize(width: 512, height: 256, quality: 25) {
              src
            }
          }
        }
      }
    }
    # parentPost: markdownRemark(fields: { slug: { eq: $parentSlug } }) {
    #   id
    #   fields { slug }
    #   frontmatter { title }
    # }
    childPosts: allMarkdownRemark(
      filter: { fields: { slug: { regex: $slug } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          html
          fields {
            slug
          }
          frontmatter {
            title
            ep
            description
            lang
            linkExternal {
              label
              url
            }
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 512, maxHeight: 256, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                resize(width: 64, height: 64, quality: 25) {
                  src
                }
              }
            }
            date(formatString: "YYYY/MM/DD")
          }
        }
      }
    }
  }
`
