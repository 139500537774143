import React, { Component } from "react"
import PropTypes from "prop-types"
import { BannerWrapper } from "./Banner.css"
///

class Banner extends Component {
  render() {
    const { children } = this.props
    return <BannerWrapper {...this.props}>{children}</BannerWrapper>
  }
}

export default Banner

Banner.propTypes = {
  children: PropTypes.node,
  width: PropTypes.oneOf(["full", "container"]),
}

Banner.defaultProps = {
  width: "full",
}
